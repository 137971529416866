import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
const MatchOdds = ({ onClose, show, detail }) => {
  const [team, setTeam] = useState(detail?.runners[0]?.RunnerName);
  const divRef = useRef(null);
  // useEffect(() => {
  //   const { current } = divRef;
  //   if (current !== null) {
  //     current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //       inline: "nearest",
  //     });
  //   }
  //   // divRef.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  // }, [divRef]);
  // console.log(divRef, "ref");
  // // element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  let data = [
    {
      rt: "1.22",
      bv: 122.3,
      time: 343566,
    },
    {
      rt: "1.22",
      bv: 122.3,
      time: 343566,
    },
    {
      rt: "1.22",
      bv: 122.3,
      time: 343566,
    },
    {
      rt: "1.22",
      bv: 122.3,
      time: 343566,
    },
    {
      rt: "1.22",
      bv: 122.3,
      time: 343566,
    },
    {
      rt: "1.22",
      bv: 122.3,
      time: 343566,
    },
    {
      rt: "1.22",
      bv: 122.3,
      time: 343566,
    },
  ];
  const [back, setBack] = useState(data);
  const [lay, setLay] = useState(data);
  return (
    <Modal show={show} onHide={onClose}>
      <div className="market-depth-modal slide-up">
        <div className="market-title">
          <h5>Market Depth</h5>
          {/* <Button
            className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
            onClick={() => onClose()}
          > */}
          {/* <RxCross2
            onClick={() => onClose()}
            className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
          /> */}
          {/* </Button> */}
        </div>

        <div className="market-depth-body">
          <div style={{ fontSize: "14px" }} className="match-point p-2 pb-0">
            <span className="master-pass">{detail?.eventName}</span>
            <span
              style={{ marginLeft: "4px", fontWeight: "700" }}
              className="master-pass"
            >
              - Match Odds
            </span>
          </div>

          <div className="select-container p-2">
            <Form.Select>
              {detail?.runners?.map((res) => {
                return (
                  <option className={res?.RunnerName}>{res?.RunnerName}</option>
                );
              })}
            </Form.Select>
          </div>

          <Table className="border">
            <tbody>
              <tr>
                <td className="text-center">
                  Total matched
                  <strong class="d-block">26855.14</strong>
                </td>
                <td className="text-center">
                  Selection Volume
                  <strong class="d-block">26547.56</strong>
                </td>
                <td className="text-center">
                  Last price
                  <strong class="d-block">1.13</strong>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="balance-label position-relative exchange-label">
            Price, Exchange Available and Traded
          </div>

          <div className="d-flex justify-content-between mt-2 px-3 mb-2">
            <span
              style={{ padding: "2px 4px" }}
              className="bet-name d-flex align-items-center lay rounded fw-normal"
            >
              <FaAngleLeft size={14} className="text-success" />
              to Lay
            </span>
            <span
              onClick={() =>
                divRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                })
              }
              style={{ padding: "2px 4px" }}
              className="bet-name d-flex align-items-center  back rounded fw-normal"
            >
              to Back
              <FaAngleRight size={14} vclassName="text-danger" />
            </span>
          </div>

          <div className="to-back-to-lay">
            <Table ref={divRef} responsive className="">
              <thead>
                <tr>
                  {" "}
                  {lay?.map((res) => {
                    return (
                      <th className="fw-normal text-center bet-name lay rounded-0">
                        <strong className="d-block">{res?.rt}</strong>
                        {res?.bv}
                      </th>
                    );
                  })}
                  {back?.map((res) => {
                    return (
                      <th className="fw-normal text-center bet-name back rounded-0">
                        <strong className="d-block">{res?.rt}</strong>
                        {res?.bv}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {lay?.map((res) => {
                    return <td>{res?.time}</td>;
                  })}
                  {back?.map((res) => {
                    return <td>{res?.time}</td>;
                  })}
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>{" "}
    </Modal>
  );
};

export default MatchOdds;
