import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { Button, Table } from "react-bootstrap";
import obj from "../../utils/constants";
import DropDown from "./DropDown";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import { FancyListDropdown } from "./FancyListDropdown";
import { FiRefreshCcw } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { FaArrowAltCircleRight } from "react-icons/fa";
import dayjs from "dayjs";
import ListHeader from "./ListHeader";
import MatchOdds from "../../components/MatchOdds";
const BetTypeListing = ({ title, data, showOdds, tileFile, refresh }) => {
  const [showMatch, setShowMatch] = useState(false);
  let { user } = useContext(AuthContext);
  const [selectData, setSelectData] = useState({});
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const matchDetail = (updated, game) => {
    return (
      <div className="account-table match-odd-table match-odd-table-new">
        <div className="responsive">
          <Table>
            <tbody className="header-new">
              <tr>
                <td width="10%" rowSpan="2">
                  <strong
                    onClick={() =>
                      window
                        .open(
                          "/risk-betfair",
                          "_blank",
                          "width=900, height=700"
                        )
                        .focus()
                    }
                    style={{
                      color: "white",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {game}
                  </strong>
                </td>
                <td rowSpan="2">
                  <strong></strong>
                </td>
                <td width="7%" className=" border-0">
                  <strong>{title == "Fancy Bet" ? "Min" : "1"}</strong>
                </td>

                <td width="7%" className="border-0">
                  <strong>X</strong>
                </td>
                <td width="7%" className="border-0">
                  <strong>{title == "Fancy Bet" ? "Max" : "2"}</strong>
                </td>
                <td
                  style={{ borderLeft: "1px solid #d1d5dbcc" }}
                  rowSpan="2"
                  width={"8%"}
                  className="text-center"
                >
                  <strong> Downline P/L</strong>
                </td>
              </tr>
            </tbody>
            <tbody className="match-tbody">
              {isEmpty(updated) && (
                <tr>
                  <td colSpan={7}>No Record Found</td>
                </tr>
              )}
              {updated?.length > 0 &&
                updated.map((res, index) => {
                  if (
                    title == "Book Maker"
                      ? res?.bookmakerRunners
                      : title == "Toss"
                      ? res?.runnerData
                      : res?.runners
                  ) {
                    return (
                      <>
                        <tr key={index + 1}>
                          <td
                            style={{ borderRight: "1px solid #d1d5dbcc" }}
                            className="text-center"
                          >
                            {dayjs(res.eventDateTime).format("YYYY-MM-DD")}
                          </td>
                          <td className="bg-yellow border-0">
                            <a>
                              <>
                                {res?.marketId == id ? (
                                  <AiFillMinusCircle
                                    onClick={() => {
                                      setShowMatch(false);
                                      setId("");
                                    }}
                                    size={20}
                                    style={{ marginRight: "3px" }}
                                    color="rgba(59, 130, 246, 1)"
                                  />
                                ) : (
                                  <AiFillPlusCircle
                                    size={20}
                                    onClick={() => {
                                      setShowMatch(true);
                                      setId(res?.marketId);
                                    }}
                                    style={{ marginRight: "3px" }}
                                    color="rgba(59, 130, 246, 1)"
                                  />
                                )}
                              </>
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/match-details/${res?.eventId}/${res?.marketId}`
                                  )
                                }
                                className="green-btn"
                                style={{
                                  padding: "2px 4px",
                                  fontSize: "11px",
                                  marginRight: "5px",
                                }}
                              >
                                {"View Details"}
                              </Button>
                              <strong
                                // onClick={() =>
                                //   navigate(
                                //     `/match-details/${res?.eventId}/${res?.marketId}`
                                //   )
                                // }
                                onClick={() =>
                                  title == "Book Maker"
                                    ? navigate(
                                        `/match-details/${res?.eventId}/${res?.marketId}`
                                      )
                                    : window
                                        .open(
                                          `/risk-betfair/${res?.eventId}/${res?.marketId}`,
                                          "_blank",
                                          "width=900, height=700"
                                        )
                                        .focus()
                                }
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color: "#2779bf",
                                }}
                              >
                                {res.eventName}
                              </strong>{" "}
                              <FaArrowAltCircleRight
                                style={{ margin: "0 3px" }}
                                size={14}
                                color="rgba(107, 114, 128, 1)"
                              />
                              <strong
                                // onClick={() =>
                                //   navigate(
                                //     `/match-details/${res?.eventId}/${res?.marketId}`
                                //   )
                                // }
                                onClick={() => {
                                  if (tileFile == "matchOdds") {
                                    setShow(true);
                                    setSelectData(res);
                                  }
                                }}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color: "#2779bf",
                                }}
                              >
                                {res?.bookmakerCentralizedId
                                  ? "Bookmaker"
                                  : res?.market}{" "}
                              </strong>
                            </a>
                          </td>

                          {res?.runners?.length > 0 &&
                            res?.runners?.map((item, index) => {
                              let position = res?.position?.find(
                                (res) => res?.selectionId == item?.SelectionId
                              )?.position;
                              return (
                                <>
                                  <td className="border-0 bg-yellow">
                                    <p
                                      className={
                                        Math.sign(position) == -1
                                          ? "text-blue-new"
                                          : "text-danger"
                                      }
                                    >
                                      ({Math.sign(position) == -1 ? "" : "-"}{" "}
                                      {Math.abs(position).toFixed(2)})
                                    </p>
                                  </td>
                                  {index == 0 && res?.runners?.length == 2 && (
                                    <td className="border-0 bg-yellow">-</td>
                                  )}
                                </>
                              );
                            })}

                          {res?.runnerData?.length > 0 &&
                            res?.runnerData?.map((item, index) => {
                              let position = res?.position?.find(
                                (res) => res?.selectionId == item?.SelectionId
                              )?.position;
                              return (
                                <>
                                  <td className="border-0 bg-yellow">
                                    <p
                                      style={{ paddingTop: "5px" }}
                                      className={
                                        Math.sign(position) == -1
                                          ? "text-blue-new"
                                          : "text-danger"
                                      }
                                    >
                                      ({Math.sign(position) == -1 ? "" : "-"}{" "}
                                      {Math.abs(position).toFixed(2)})
                                    </p>
                                  </td>
                                  {index == 0 &&
                                    res?.runnerData?.length == 2 && (
                                      <td className="border-0 bg-yellow">-</td>
                                    )}
                                </>
                              );
                            })}

                          {res?.bookmakerRunners?.length > 0 &&
                            res?.bookmakerRunners?.map((item, index) => {
                              let position = res?.position?.find(
                                (res) => res?.selectionId == item?.selectionID
                              )?.position;
                              return (
                                <>
                                  <td className=" bg-yellow">
                                    <p
                                      className={
                                        Math.sign(position) == -1
                                          ? "text-blue-new"
                                          : "text-danger"
                                      }
                                    >
                                      ({Math.sign(position) == -1 ? "" : "-"}{" "}
                                      {position && position !== 0
                                        ? Math.abs(position).toFixed(2)
                                        : 0}
                                      )
                                    </p>
                                  </td>
                                  {index == 0 &&
                                    res?.bookmakerRunners?.length == 2 && (
                                      <td className="border-0 bg-yellow">-</td>
                                    )}
                                </>
                              );
                            })}
                          <td
                            style={{ borderLeft: "1px solid #d1d5dbcc" }}
                            className="border-right-0  text-center"
                          >
                            <Link
                              onClick={() => {
                                window
                                  .open(
                                    title == "Fancy Bet"
                                      ? `/DownlinePnl-Fancy/${res?.fancyName}/${res?.eventId}/${res?.marketId}/${res?.selectionId}`
                                      : `/DownlinePnl/${user._id}/${user.userType}/${res?.eventId}/${title}`,
                                    "_blank",
                                    "width=900, height=700"
                                  )
                                  .focus();
                              }}
                              className="btn-light-view"
                            >
                              {title == "Fancy Bet" ? "Book" : "View"}
                            </Link>
                          </td>
                        </tr>

                        {res?.marketId == id ? (
                          <DropDown
                            showMatch={showMatch}
                            title={title}
                            layData={res.lay_odds || []}
                            data={
                              title == "Book Maker"
                                ? res?.bookmakerRunners
                                : title == "Toss"
                                ? res?.runnerData
                                : res?.runners
                            }
                            res={res}
                            backData={res.back_odds || []}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    );
                  }
                })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <div className="risk-management-table">
      <ListHeader
        title={title}
        downloadShow={data?.length > 0 ? true : false}
        fileTile={tileFile}
        refresh={refresh}
        csvData={
          data?.length > 0
            ? [
                [
                  "Sports",
                  "Market Date",
                  "Event/Market Name",
                  "Player P/L 1",
                  "Player P/L X",
                  "Player P/L 2",
                ],
                ...data?.map(
                  ({ gameType, eventDateTime, eventName, position }) => [
                    gameType,
                    eventDateTime,
                    eventName,
                    position?.length > 0 ? position[0]?.position : "",
                    position?.length > 2 ? position[2]?.position : "",
                    position?.length > 0 ? position[1]?.position : "",
                  ]
                ),
              ]
            : []
        }
      />
      {data.length > 0 ? (
        <>
          {showOdds?.cricket &&
            matchDetail(
              data?.filter((res) => {
                return res?.gameType == "cricket";
              }),
              "Cricket"
            )}
          {showOdds?.tennis &&
            matchDetail(
              data?.filter((res) => {
                return res?.gameType == "tennis";
              }),
              "Tennis"
            )}
          {showOdds?.soccer &&
            matchDetail(
              data?.filter((res) => {
                return res?.gameType == "soccer";
              }),
              "Soccer"
            )}
        </>
      ) : (
        <div id="noData_MATCH_ODDS" class="bg-blueGray-200 p-4 mb-2">
          There are currently no matched bets for this markets
        </div>
      )}
      {show && (
        <MatchOdds
          detail={selectData}
          show={show}
          onClose={() => setShow(false)}
        />
      )}
    </div>
  );
};

export default BetTypeListing;
